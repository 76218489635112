export function progressiveLoading(){

        if (this.camera.position.z > 1.5 && this.scene.children.length === 2) {
            this.scene.add(this.tubeMesh36);
        }


        if (this.camera.position.z > 2.5 && this.scene.children.length === 3) {
            this.scene.add(this.tubeMesh37);
        }

        if (this.camera.position.z > 3.5 && this.scene.children.length === 4) {
            this.scene.add(this.tubeMesh38);
        }


        if (this.camera.position.z > 4.5 && this.scene.children.length === 5) {
            this.scene.add(this.tubeMesh39);
        }


        if (this.camera.position.z > 5.5 && this.scene.children.length === 6) {
            this.scene.add(this.tubeMesh40);
        }


        if (this.camera.position.z > 6.5 && this.scene.children.length === 7) {
            this.scene.add(this.tubeMesh41);
        }


        if (this.camera.position.z > 7.5 && this.scene.children.length === 8) {
            this.scene.add(this.tubeMesh42);
        }


        if (this.camera.position.z > 8.5 && this.scene.children.length === 9) {
            this.scene.add(this.tubeMesh43);
        }


        if (this.camera.position.z > 9.5 && this.scene.children.length === 10) {
            this.scene.add(this.tubeMesh44);
        }

        if (this.camera.position.z > 10.5 && this.scene.children.length === 11) {
            this.scene.add(this.tubeMesh45);
        }

        if (this.camera.position.z > 11.5 && this.scene.children.length === 12) {
            this.scene.add(this.tubeMesh46);
        }


        if (this.camera.position.z > 12.5 && this.scene.children.length === 13) {
            this.scene.add(this.tubeMesh47);
        }


        if (this.camera.position.z > 13.5 && this.scene.children.length === 14) {
            this.scene.add(this.tubeMesh48);
        }


        if (this.camera.position.z > 14.5 && this.scene.children.length === 15) {
            this.scene.add(this.tubeMesh49);
        }


        if (this.camera.position.z > 15.5 && this.scene.children.length === 16) {
            this.scene.add(this.tubeMesh50);
        }

        if (this.camera.position.z > 16.5 && this.scene.children.length === 17) {
            this.scene.add(this.tubeMesh51);
        }

        if (this.camera.position.z > 17.5 && this.scene.children.length === 18) {
            this.scene.add(this.tubeMesh52);
        }

        if (this.camera.position.z > 18.5 && this.scene.children.length === 19) {
            this.scene.add(this.tubeMesh53);
        }

        if (this.camera.position.z > 19.5 && this.scene.children.length === 20) {
            this.scene.add(this.tubeMesh54);
        }

        if (this.camera.position.z > 20.5 && this.scene.children.length === 21) {
            this.scene.add(this.tubeMesh55);
        }

        if (this.camera.position.z > 21.5 && this.scene.children.length === 22) {
            this.scene.add(this.tubeMesh56);
        }

        if (this.camera.position.z > 22.5 && this.scene.children.length === 23) {
            this.scene.add(this.tubeMesh57);
        }

        if (this.camera.position.z > 23.5 && this.scene.children.length === 24) {
            this.scene.add(this.tubeMesh58);
        }

        if (this.camera.position.z > 24.5 && this.scene.children.length === 25) {
            this.scene.add(this.tubeMesh59);
        }


        if (this.camera.position.z > 25.5 && this.scene.children.length === 26) {
            this.scene.add(this.tubeMesh60);
        }

        if (this.camera.position.z > 26.5 && this.scene.children.length === 27) {
            this.scene.add(this.tubeMesh61);
        }

        if (this.camera.position.z > 27.5 && this.scene.children.length === 28) {
            this.scene.add(this.tubeMesh62);
        }


        if (this.camera.position.z > 28.5 && this.scene.children.length === 29) {
            this.scene.add(this.tubeMesh63);
        }


        if (this.camera.position.z > 30 && this.scene.children.length === 30) {
            this.scene.add(this.tubeMesh64);
        }

        if (this.camera.position.z > 33 && this.scene.children.length === 31) {
            this.scene.add(this.tubeMesh65);
        }

    }