

export const colors = [

    //march21
    0xdad2f7, 0xb6adea,
    0xb6adea, 0xb6adea,
    0xb6adea, 0xb6adea,

    //feb 
    0xabf4ee,
    0xd4f9f6, 0xd4f9f6,
    0xd4f9f6, 0xd4f9f6,

    //jan
    0xe8c5d2, 0xede1e6,
    0xede1e6, 0xede1e6,
    0xede1e6,

    //dec
    0xb4edd2, 0xd0efe1,
    0xd0efe1, 0xd0efe1,
    0xd0efe1,

    //nov
    0xe8d5a2, 0xeadebb,
    0xeadebb, 0xeadebb,
    0xeadebb,

    //oct
    0xeddbd5, 0xefe6e3,
    0xefe6e3, 0xefe6e3,
    0xefe6e3,

    //sept
    0x9dc8e0, 0xc0d6e2,
    0xc0d6e2, 0xc0d6e2,
    0xc0d6e2,

    //aug
    0xe2bb93, 0xe8c5a2,
    0xe8c5a2, 0xe8c5a2,
    0xe8c5a2,

    //july
    0xc8a0d6, 0xd2b1dd,
    0xd2b1dd, 0xd2b1dd,
    0xd2b1dd,

    //june
    0xe2dcd0, 0xe5e2dc,
    0xe5e2dc, 0xe5e2dc,
    0xe5e2dc,

    //may
    0xdda09b, 0xe2b8b3,
    0xe2b8b3, 0xe2b8b3,
    0xe2b8b3,

    //april
    0xbfe5b3, 0xd3edcb,
    0xd3edcb, 0xd3edcb,
    0xd3edcb,

    //march20
    0xb1c5e2, 0xc7d4ea,
    0xc7d4ea, 0xc7d4ea,
    0xc7d4ea

]